import React, { useState, useEffect } from "react";
import "./App.css";
import Demo from "./components/demo";
import Footer from "./components/footer";
import Hero from "./components/hero";
import Navbar from "./components/navbar";
import OurTeam from "./components/ourteam";
import Pricing from "./components/pricing";
import UseCases from "./components/usecases";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating a delay of 2 seconds for the components to load
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="loader w-screen h-screen">
          <img
            src={"../klogo.png"}
            alt="loading"
            className="py-44 mx-auto w-1/4"
          />
          <img
            src={"../loader.gif"}
            alt="loader"
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          />
        </div>
      ) : (
        <>
          <Navbar />
          <div className="App bg-mainbg bg-cover flex flex-col absolute z-10">
            <div id="hero">
              <Hero />
            </div>
            <div id="use-cases">
              <UseCases />
            </div>
            <div id="demo">
              <Demo />
            </div>
            <div id="pricing">
              <Pricing />
            </div>
            <div id="ourteam">
              <OurTeam />
            </div>
            <div id="footer">
              <Footer />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default App;
