import React from "react";
import { useEffect, useState } from "react";

const Hero = () => {
  const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div className="flex flex-col md:-gap-8 gap-12 absolute left-0 md:top-8 top-48 items-center w-screen">
        <div className="md:w-full w-screen md:-mb-6">
          <img
            src={"../lc1.png"}
            className=" mr-auto z-10"
            alt="logo"
            style={{ transform: `translateY(${scrollY * 0.3}px)` }}
          />
        </div>
        <div className="md:w-full max-w-screen">
          <img
            src={"../rc1.png"}
            className=" md:ml-auto z-10 ml-auto"
            alt="logo"
            style={{ transform: `translateY(${scrollY * 0.3}px)` }}
          />
        </div>

        <div className="md:w-full max-w-screen">
          <img
            src={"../lc2.png"}
            className=" mr-auto z-10"
            alt="logo"
            style={{ transform: `translateY(${scrollY * 0.3}px)` }}
          />
        </div>

        <div className="md:w-full max-w-screen">
          <img
            src={"../rc22.png"}
            className=" ml-auto z-10"
            alt="logo"
            style={{ transform: `translateY(${scrollY * 0.3}px)` }}
          />
        </div>
      </div>
      <div className="flex flex-col items-left gap-6 justify-center md:min-h-screen  md:px-44 font-urbanist md:w-auto w-5/6 relative md:top-6 md:py-0 mx-auto md:mx-0 max-h-screen md:max-h-none transform translate-y-20 md:translate-y-0 md:mb-0 mb-24">
        <div className="md:px-8 px-4 glass md:py-24 rounded-xl z-20 flex flex-col justify-center items-start text-left md:gap-12 gap-6 py-6 relative">
          <h1 class="md:text-4xl text-3xl md:w-3/4 w-full text-white md:mb-4 font-quicksand">
            Unlock <span class="font-bold">AI-Powered Persona</span> Insights
            for Market Success.
          </h1>
          <p class="text-white md:text-xl text-lg md:w-2/3 w-3/4 md:mb-6">
            {/* We use AI personas to deliver precise market insights through
            tailored surveys, enabling businesses to test ideas, optimize
            pricing, and validate strategies with data-backed decisions. */}
            We use AI personas to deliver precise market insights through
            tailored surveys, enabling businesses to prioritize ideas, optimize
            pricing, and validate strategies with confidence, all supported by
            data-backed decisions, comprehensive analyses, and competitor
            insights.
          </p>
          <button
            class="bg-btnbg text-black py-2 px-4 bg-cover bg-center rounded-lg hover:text-white hover:translate-x-2 transition duration-300 mb-6 md:mb-0"
            onClick={() =>
              document
                .getElementById("footer")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            Join the waitlist
          </button>
          <img
            src={"../realman.png"}
            className="absolute w-1/3 right-0 -bottom-4 md:visible invisible"
            alt="man"
          />
        </div>
      </div>
    </>
  );
};

export default Hero;
