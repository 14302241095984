import React from "react";

const Demo = () => {
  return (
    <div className="flex flex-col items-left gap-6 justify-center md:min-h-screen  md:px-44 font-urbanist md:w-auto px-6 py-32 md:py-0 mx-auto md:mx-0">
      <h1 className="text-white md:text-4xl text-3xl mb-4 z-50">Product</h1>
      <div className="md:p-8 p-4 bg-glassbg backdrop-blur-md bg-opacity-30 rounded-xl z-20">
        <video src={"../demo.mov"} className="rounded-xl" controls />
      </div>
    </div>
  );
};

export default Demo;
