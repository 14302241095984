import React from "react";

const UseCases = () => {
  return (
    <div className="flex flex-col items-left gap-6 justify-center md:min-h-screen  md:px-44 font-urbanist md:w-auto w-3/4 py-32 md:py-0 mx-auto md:mx-0">
      <h1 className="text-white md:text-4xl text-3xl mb-4 z-50">Use Cases</h1>
      <div className="flex md:flex-row gap-6 justify-center flex-col">
        <div className="flex-1 px-8 glass2 py-12 rounded-xl z-20 flex flex-col justify-evenly items-center text-center gap-12">
          <img src={"../newmarket.png"} className="w-1/3" alt="market" />
          <h1 className="text-white text-2xl font-bold">
            Test New Market Entry
          </h1>
          <p className="text-white md:text-xl text-lg">
            Evaluate the potential success of entering a new market by using
            AI-driven personas to simulate consumer reactions and market
            viability.
          </p>
        </div>
        <div className="flex-1 px-8 glass2 py-12 rounded-xl z-20 flex flex-col justify-evenly items-center text-center gap-12">
          <img src={"../consbeh.png"} className="w-1/2" alt="market" />
          <h1 className="text-white text-2xl font-bold">
            Consumer Behaviour Insights
          </h1>
          <p className="text-white md:text-xl text-lg">
            Discover what motivates your audience with AI-driven personas,
            gaining insights into their attitudes, needs, behaviors, and
            customer journeys.
          </p>
        </div>
        <div className="flex-1 px-8 glass2 py-12 rounded-xl z-20 flex flex-col justify-evenly items-center text-center gap-12">
          <img src={"../prodstrat.png"} className="w-1/3" alt="market" />
          <h1 className="text-white text-2xl font-bold">Competitor Analysis</h1>
          <p className="text-white md:text-xl text-lg">
            Assess the competitive landscape by leveraging personas to identify
            competitors and uncover market gaps for optimized success.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UseCases;
