import React, { useState } from "react";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state

  const handleSubmit = async (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      alert("Invalid email format. Please enter a valid email address.");
      return;
    }

    setLoading(true); // Set loading state to true

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbxwtrrcGU6u_zilLtObAcumK9s_4gkhfBqTi88LiM87f1N2DBlXvA7IzSyJnUQVr82kTA/exec",
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            email: email,
          }),
        }
      );

      console.log(response);
      const result = await response.text();
      if (response.status === "success") {
        setMessage("Successfully added to the waitlist!");
        setEmail("");
        alert("Successfully added to the waitlist!");
      } else {
        console.log(response.ok);
        setMessage("Successfully added to the waitlist!");
        setEmail("");
        alert("Successfully added to the waitlist!");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("There was an error. Please try again.");
      alert("There was an error. Please try again.");
    } finally {
      setLoading(false); // Set loading state back to false
    }
  };

  return (
    <div className=" flex flex-col gap-2 backdrop-blur-md bg-footerbg bg-cover h-[80vh] bg-opacity-20 justify-center md:w-auto w-screen">
      <h1 className="text-bl text-xl md:text-2xl">Join the Waitlist</h1>
      <div className="flex flex-row gap-4 justify-center mb-4">
        <input
          type="text"
          className="rounded-xl py-2 px-4 shadow-lg"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          className="bg-btnbg bg-center text-white rounded-xl p-2 shadow-sm hover:shadow-lg hover:text-black transition z-20"
          disabled={loading} // Disable the button when loading is true
          onClick={() => {
            handleSubmit(email);
          }}
        >
          {loading ? "Loading..." : "Join"}{" "}
          {/* Render the loader or "Join" text */}
        </button>
      </div>
      <div className="flex flex-row gap-2 md:gap-6 w-full justify-center py-8 absolute bottom-0 backdrop-blur-lg items-center">
        <p className="text-sm md:text-md text-white">@2024 Citrana</p>
        <p className="text-sm md:text-md text-white">About Us</p>
        <p className="text-sm md:text-md text-white">Contact Us</p>
        <p className="text-sm md:text-md text-white">Terms and Conditions</p>
        <p className="text-sm md:text-md text-white">Privacy Policy</p>
      </div>
    </div>
  );
};

export default Footer;
