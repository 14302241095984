import React from "react";

const Pricing = () => {
  return (
    <div className="flex flex-col items-left gap-6 justify-center md:min-h-screen  md:px-44 font-urbanist md:w-auto w-3/4 py-32 md:py-0 mx-auto md:mx-0">
      <h1 className="text-white md:text-4xl text-3xl mb-4 z-20">Pricing</h1>
      <div className="flex md:flex-row flex-col gap-6 justify-center ">
        <div className="flex-1 bg-white bg-opacity-20 backdrop-blur-lg md:py-12 py-8 rounded-xl z-20 flex flex-col justify-start items-center text-left gap-6 shadow-lg">
          <h1 className="text-white md:text-3xl text-2xl">Startup Model</h1>
          <h1 className="text-white bg-mainbgrot text-center md:py-16 py-12 w-full md:text-2xl text-xl font-bold">
            <span className="md:text-5xl text-3xl">$300</span>
            /mo
          </h1>
          <ul className="flex flex-col gap-4 text-center">
            <li className="text-white text-lg md:text-xl px-6 md:px-12">
              <span className="font-bold text-xl md:text-2xl">10</span> runs per
              month
            </li>
            <li className="text-white text-lg md:text-xl px-6 md:px-12">
              <span className="font-bold text-xl md:text-2xl">30</span> 1 on 1
              custom interviews with personas
            </li>
          </ul>
        </div>
        <div className="flex-1 bg-white bg-opacity-20 backdrop-blur-lg md:py-12 py-8 rounded-xl z-20 flex flex-col justify-start items-center text-left gap-6 shadow-lg">
          <h1 className="text-white md:text-3xl text-2xl">Enterprise Model</h1>
          <h1 className="text-white bg-mainbgrot text-center py-16 w-full text-2xl font-bold">
            <span className="md:text-5xl text-3xl">$3000-$10000</span>
            /year
          </h1>
          <ul className="flex flex-col gap-4 text-center">
            <li className="text-white text-lg md:text-xl px-6 md:px-12">
              <span className="font-bold text-xl md:text-2xl">30</span> runs per
              month
            </li>
            <li className="text-white text-lg md:text-xl px-6 md:px-12">
              <span className="font-bold text-xl md:text-2xl">Upto 50</span> 1
              on 1 custom interviews with personas for extra testing
            </li>
            <li className="text-white text-lg md:text-xl px-6 md:px-12">
              One time in depth data collection for improved company specific
              insights at{" "}
              <span className="font-bold text-xl md:text-2xl">$5000</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
